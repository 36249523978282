import React from "react"
import { Divider, Empty, Typography } from "antd"
import "antd/dist/antd.css"

const { Title } = Typography

const IndexPage = () => (
    <>
        <Title>GLN Digital</Title>
        <Divider />
        <Empty description="Fique de olho!" />
    </>
)

export default IndexPage

